import React from "react";
import { PageLayout, Background } from "@bluefin/components";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class BeerPage extends React.PureComponent {
  render() {
    return (
      <WebsiteLayout>
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"beer-hero"} />
          }
          subfooter={false}
          className={"beer-page"}
        >
          <div className={"iContainer"}>
            <iframe
              src={"https://business.untappd.com/embeds/iframes/31780/122649"}
              frameborder={"0"}
              width={"100%"}
              height={"100%"}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}
